import axios from 'axios';
import AuthService from './AuthService';

const API_URL = '/api/experiments';

const getAuthHeader = () => {
    const token = AuthService.getToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchExperiments = async () => {
    try {
        console.log("Fetching experiments...");
        const response = await axios.get(API_URL, { headers: getAuthHeader() });
        console.log("API response:", response);
        console.log("Experiments data:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching experiments:", error);
        console.error("Error response:", error.response);
        throw error;
    }
};

export const addExperiment = async (experimentData) => {
    const response = await axios.post(API_URL, experimentData, { headers: getAuthHeader() });
    return response.data;
};

export const updateExperiment = async (experimentId, experimentData) => {
    const response = await axios.put(`${API_URL}/${experimentId}`, experimentData, { headers: getAuthHeader() });
    return response.data;
};

export const deleteExperiment = async (experimentId) => {
    await axios.delete(`${API_URL}/${experimentId}`, { headers: getAuthHeader() });
};

export const trainExperiment = async (experimentId, trainingData = {}) => {
    try {
        const data = {
            currentEpisode: trainingData.currentEpisode ?? 1,
            reward: trainingData.reward ?? 0,
            accuracy: trainingData.accuracy ?? 0,
            status: trainingData.status ?? 'Running'
        };
        const response = await axios.post(`${API_URL}/${experimentId}/train`, data, {
            headers: {
                ...getAuthHeader(),
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error training experiment:", error);
        throw error;
    }
};

export const saveExperimentModels = async (experimentId) => {
    const response = await axios.post(`${API_URL}/${experimentId}/save-models`, {}, { headers: getAuthHeader() });
    return response.data;
};

export const loadExperimentModels = async (experimentId) => {
    const response = await axios.post(`${API_URL}/${experimentId}/load-models`, {}, { headers: getAuthHeader() });
    return response.data;
};

export const getExperimentDetails = async (experimentId) => {
    const response = await axios.get(`${API_URL}/${experimentId}`, { headers: getAuthHeader() });
    return response.data;
};

export const stopExperiment = async (experimentId) => {
    try {
        if (!experimentId) {
            throw new Error("Invalid experiment ID");
        }
        const response = await axios.post(`${API_URL}/${experimentId}/stop`, {}, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error("Error stopping experiment:", error.response?.data?.message || error.message);
        throw error;
    }
};

export const resumeExperiment = async (experimentId) => {
    const response = await axios.post(`${API_URL}/${experimentId}/resume`, {}, { headers: getAuthHeader() });
    return response.data;
};

export const retrainExperiment = async (experimentId) => {
    const response = await axios.post(`${API_URL}/${experimentId}/retrain`, {}, { headers: getAuthHeader() });
    return response.data;
};

export const stopAllExperiments = async () => {
    const response = await axios.post(`${API_URL}/stop-all`, {}, { headers: getAuthHeader() });
    return response.data;
};