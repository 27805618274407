<template>
  <div class="container mx-auto p-4">
    <!-- Notification component -->
    <div
      v-if="showNotification"
      class="fixed top-5 left-1/2 transform -translate-x-1/2 z-50 px-4 py-2 rounded-md shadow-md transition-opacity duration-300"
      :class="{ 'opacity-0': notificationFading }"
    >
      {{ notificationMessage }}
    </div>

    <RobotSwarmController
      ref="robotSwarmControllerRef"
      @robotIdsUpdated="updateRobots"
      @sendCommand="handleCommand"
      @experiment-added="handleExperimentAdded"
      @experiment-updated="handleExperimentUpdated"
      @training-started="handleTrainingStarted"
      @training-stopped="handleTrainingStopped"
      @log-updated="handleLogUpdated"
      @show-notification="displayNotification"
    />

    <!-- Experiment List -->
    <div class="bg-white shadow rounded-lg p-4 mt-4">
      <ExperimentList
        ref="experimentListRef"
        :experiments="filteredExperiments"
        :updateExperiment="updateExperiment"
        :openSimulator="openSimulator"
        :robotSwarmControllerData="robotSwarmControllerData"
        :getConnectedRobots="getConnectedRobots"
        @experiment-added="handleExperimentAdded"
        @experiment-updated="handleExperimentUpdated"
        @experiment-list-updated="fetchExperimentsList"
        @train-experiment="handleTrainExperiment"
        @stop-experiment="handleStopExperiment"
        @retrain-experiment="handleRetrainExperiment"
        @resume-experiment="handleResumeExperiment"
        @delete-experiment="handleDeleteExperiment"
        @view-experiment-details="showExperimentDetails"
        @save-experiment-models="handleSaveExperimentModels"
        @load-experiment-models="handleLoadExperimentModels"
        @experiment-form-opened="handleExperimentFormOpened"
        @experiment-form-closed="handleExperimentFormClosed"
        @start-experiment-training="handleStartExperimentTraining"
      />
    </div>

    <!-- Experiment Details Modal -->
    <Modal v-if="selectedExperiment" @close="selectedExperiment = null">
      <template v-slot:header>
        <h2 class="text-2xl font-bold text-gray-800">{{ selectedExperiment.name }}</h2>
      </template>
      <template v-slot:body>
        <ExperimentDetails
          :experiment="selectedExperiment"
          @experiment-updated="handleExperimentUpdated"
        />
      </template>
    </Modal>

    <!-- Log Terminal -->
    <div
      class="mt-6 bg-terminal-black text-terminal-green shadow-lg rounded-lg overflow-hidden font-mono"
    >
      <div class="bg-gray-800 p-2 text-white">Logs</div>
      <div ref="logContainer" class="logs" @wheel="userScrolled = true">
        <div class="log-entry" v-for="log in logs" :key="log.id">
          <div :class="`log-${log.type}`">
            <i v-if="log.testMode" class="fas fa-flask" style="color: violet"></i>
            <i :class="`fas ${getIcon(log.type)}`"></i>
            <span class="timestamp">{{ log.timestamp }}</span>
            <span class="message">{{ log.message }}</span>
            <template v-if="log.topic">
              | <span class="topic">{{ log.topic }}</span>
            </template>
            <template v-if="log.payload">
              | <span class="payload">{{ log.payload }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports
import { ref, onMounted, onUnmounted, computed, watch, nextTick, provide } from "vue";
import { useRouter } from "vue-router";
import AuthService, { handleGlobalError } from "@/services/AuthService";
import { fetchExperiments } from "@/services/ExperimentService";

// Component imports
import RobotSwarmController from "@/components/RobotSwarmController.vue";
import ExperimentList from "@/components/ExperimentList.vue";
import Modal from "@/components/Modal.vue";
import ExperimentDetails from "@/components/ExperimentDetails.vue";

// Router
const router = useRouter();

// Refs for components and DOM elements
const robotSwarmControllerRef = ref(null);
const experimentListRef = ref(null);
const logContainer = ref(null);

// Data refs
const currentUser = ref(null);
const experiments = ref([]);
const robots = ref([]);
const selectedExperiment = ref(null);
const logs = ref([]);

// State refs
const isLoading = ref(true);
const isScrolledToBottom = ref(true);
const userScrolled = ref(false);

// Notification system
const showNotification = ref(false);
const notificationMessage = ref('');
const notificationFading = ref(false);
let notificationTimeout;

const showSimulator = ref(false);
const simulatorUrl = ref("");
const iframeKey = ref(0);

// Provide the openSimulator function to child components
provide("openSimulator", (experimentId) => {
  robotSwarmControllerRef.value.openSimulator(experimentId);
});

const robotSwarmControllerData = computed(() => {
  const data = robotSwarmControllerRef.value
    ? robotSwarmControllerRef.value.robotSwarmControllerData
    : null;
  console.log("Robot Swarm Controller Data:", data);
  return data;
});

const filteredExperiments = computed(() => {
  if (!currentUser.value || !currentUser.value._id) {
    console.log("No user logged in or user ID is missing, returning empty experiments");
    return [];
  }

  const isSimMode = robotSwarmControllerRef.value?.isTestMode;

  const filtered = experiments.value.filter((exp) => {
    return (
      exp.user_ids &&
      Array.isArray(exp.user_ids) &&
      exp.user_ids.includes(currentUser.value._id) &&
      exp.isSimulation === isSimMode
    );
  });

  return filtered;
});

const openSimulator = (experimentId) => {
  console.log('HomePage: openSimulator called with experimentId:', experimentId);
  if (robotSwarmControllerRef.value && robotSwarmControllerRef.value.openSimulator) {
    robotSwarmControllerRef.value.openSimulator(experimentId);
  } else {
    console.error('RobotSwarmController component or openSimulator method is not available');
  }
};

const displayNotification = (message, duration = 3000) => {
  // Clear any existing timeout
  if (notificationTimeout) {
    clearTimeout(notificationTimeout);
  }

  // Set the new message and show the notification
  notificationMessage.value = message;
  showNotification.value = true;
  notificationFading.value = false;

  // Set a timeout to start fading out the notification
  notificationTimeout = setTimeout(() => {
    notificationFading.value = true;
    // Hide the notification after the fade-out animation
    setTimeout(() => {
      showNotification.value = false;
    }, 300); // 300ms matches the transition duration
  }, duration);
};

const fetchExperimentsList = async () => {
  isLoading.value = true;
  try {
    const fetchedExperiments = await fetchExperiments();
    experiments.value = fetchedExperiments;
  } catch (error) {
    console.error("Failed to fetch experiments:", error);
  } finally {
    isLoading.value = false;
  }
};

const handleSimulatorMessage = (event) => {
  if (event.origin === 'https://sim.terracrawler.com' && event.data === 'simulator_unloaded') {
    console.log('Received simulator_unloaded message in HomePage');
    if (robotSwarmControllerRef.value && robotSwarmControllerRef.value.handleSimulatorUnload) {
      robotSwarmControllerRef.value.handleSimulatorUnload();
    }
  }
};

const handleExperimentAdded = async (newExperiment) => {
  try {
    experiments.value.push(newExperiment);
    await fetchExperimentsList();
  } catch (error) {
    console.error("Failed to add experiment:", error);
  }
};

// Helper function to check if simulator window is open
const isSimulatorOpen = () => {
  return robotSwarmControllerRef.value && robotSwarmControllerRef.value.isSimulatorOpen;
};

const handleTrainExperiment = async (experiment) => {
  console.log("HomePage: handleTrainExperiment called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    return;
  }

  try {
    // Check if any experiment is currently training
    const trainingExperiment = experiments.value.find((exp) => exp.status === "Running");
    if (trainingExperiment) {
      // Stop the current training before starting a new one
      await handleStopExperiment(trainingExperiment);
    }

    // Check if it's a real-world experiment
    if (!experiment.isSimulation) {
      const connectedRobots = getConnectedRobots();
      if (connectedRobots.length < experiment.numRobots) {
        alert(
          `[Error] Cannot start real-world training. Expected ${experiment.numRobots} robots, but ${connectedRobots.length} are connected.`
        );
        return;
      }
    }

    // Check if simulator window is open
    if (experiment.isSimulation && !isSimulatorOpen()) {
      console.log("Simulator window not open. Opening simulator...");
      openSimulator(experiment._id);
      // Wait for a short time to ensure the simulator is fully loaded
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    // If we've passed the checks, proceed with starting the experiment
    if (robotSwarmControllerRef.value) {
      console.log("HomePage: Calling startExperimentTraining");
      const updatedExperiment = await robotSwarmControllerRef.value.startExperimentTraining(
        experiment
      );
      if (updatedExperiment) {
        console.log("Experiment updated after starting training:", updatedExperiment);
        handleExperimentUpdated(updatedExperiment);
      } else {
        console.warn("startExperimentTraining did not return an updated experiment");
      }
    } else {
      console.error("RobotSwarmController component not available");
    }
  } catch (error) {
    console.error("Failed to start training experiment:", error);
    alert(`Failed to start training experiment: ${error.message}`);
  }
};

const updateExperiment = (updatedExperiment) => {
  const index = filteredExperiments.value.findIndex(exp => exp._id === updatedExperiment._id);
  if (index !== -1) {
    filteredExperiments.value[index] = { ...filteredExperiments.value[index], ...updatedExperiment };
  }
};

const handleExperimentUpdated = (updatedExperiment) => {
  console.log("Handling experiment update:", updatedExperiment);
  const index = experiments.value.findIndex((exp) => exp._id === updatedExperiment._id);
  if (index !== -1) {
    console.log("Updating experiment at index:", index);
    const updatedExperiments = [...experiments.value];
    updatedExperiments[index] = { ...updatedExperiments[index], ...updatedExperiment };
    experiments.value = updatedExperiments;
    updateExperiment(updatedExperiment);
  } else {
    console.log("Experiment not found in list, adding it:", updatedExperiment);
    experiments.value.push(updatedExperiment);
  }
  console.log("Updated experiments list:", experiments.value);
};

const handleStopExperiment = async (experiment) => {
  console.log("HomePage: handleStopExperiment called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    return;
  }

  try {
    if (robotSwarmControllerRef.value) {
      console.log("Calling stopExperimentTraining with experiment:", experiment);
      const stoppedExperiment = await robotSwarmControllerRef.value.stopExperimentTraining(
        experiment
      );
      console.log("Stopped experiment:", stoppedExperiment);
      if (stoppedExperiment && stoppedExperiment._id) {
        handleExperimentUpdated(stoppedExperiment);
      } else {
        console.error("stopExperimentTraining returned invalid data");
        // Fetch the latest experiment data
        await fetchExperimentsList();
      }
    } else {
      console.error("RobotSwarmController component not available");
    }

    showSimulator.value = false;
    simulatorUrl.value = "";
  } catch (error) {
    console.error("Failed to stop experiment:", error);
    displayNotification(`Failed to stop experiment: ${error.message}`);
    // Fetch the latest experiment data
    await fetchExperimentsList();
  }
};


const handleRetrainExperiment = async (experiment) => {
  console.log("HomePage: handleRetrainExperiment called", experiment);
  // Stop any running experiment before retraining
  const runningExperiment = experiments.value.find((exp) => exp.status === "Running");
  if (runningExperiment) {
    await handleStopExperiment(runningExperiment);
  }

  // Check if simulator window is open for simulation experiments
  if (experiment.isSimulation && !isSimulatorOpen()) {
    console.log("Simulator window not open. Opening simulator...");
    openSimulator(experiment._id);
    // Wait for a short time to ensure the simulator is fully loaded
    await new Promise(resolve => setTimeout(resolve, 2000));
  }

  if (robotSwarmControllerRef.value) {
    console.log("HomePage: Calling startExperimentTraining with retrain flag");
    const updatedExperiment = await robotSwarmControllerRef.value.startExperimentTraining(
      experiment,
      true
    );
    if (updatedExperiment) {
      handleExperimentUpdated(updatedExperiment);
    }
  } else {
    console.error("RobotSwarmController component not available");
  }
};

const handleResumeExperiment = async (experiment) => {
  // Stop any running experiment before resuming
  const runningExperiment = experiments.value.find((exp) => exp.status === "Running");
  if (runningExperiment && runningExperiment._id !== experiment._id) {
    await handleStopExperiment(runningExperiment);
  }

  if (robotSwarmControllerRef.value) {
    const updatedExperiment = await robotSwarmControllerRef.value.startExperimentTraining(
      experiment
    );
    if (updatedExperiment) {
      handleExperimentUpdated(updatedExperiment);
    }
  } else {
    console.error("RobotSwarmController component not available");
  }
};

const handleDeleteExperiment = async (experiment) => {
  // If the experiment to be deleted is currently running, stop it first
  if (experiment.status === "Running") {
    await handleStopExperiment(experiment);
  }
  experiments.value = experiments.value.filter((e) => e._id !== experiment._id);
};

const showExperimentDetails = (experimentDetails) => {
  selectedExperiment.value = experimentDetails;
};

const handleSaveExperimentModels = async () => {
  console.log("Experiment models saved successfully");
};

const handleLoadExperimentModels = async () => {
  console.log("Experiment models loaded successfully");
};

const updateRobots = (newRobots) => {
  robots.value = newRobots;
};

const handleCommand = (command) => {
  console.log("Command received", command);
};

const handleTrainingStarted = (experiment) => {
  console.log("HomePage: handleTrainingStarted called", experiment);
  if (experiment.isSimulation) {
    console.log("HomePage: Setting up simulator for simulation experiment");
    showSimulator.value = true;
    const token = AuthService.getToken();
    simulatorUrl.value = `https://sim.terracrawler.com?token=${token}&experiment_id=${experiment._id}`;
    console.log("HomePage: Simulator URL set", simulatorUrl.value);
    console.log("HomePage: showSimulator set to", showSimulator.value);
    iframeKey.value++; // Force refresh the iframe
  } else {
    console.log("HomePage: Not a simulation experiment, simulator not shown");
  }
};

const handleTrainingStopped = async (stoppedExperiment) => {
  console.log("HomePage: handleTrainingStopped called", stoppedExperiment);
  if (stoppedExperiment && stoppedExperiment._id) {
    console.log("Updating experiment in list with status:", stoppedExperiment.status);
    handleExperimentUpdated(stoppedExperiment);

    if (stoppedExperiment.status === "Completed") {
      console.log("Training completed successfully. Closing simulator.");
      if (robotSwarmControllerRef.value) {
        await robotSwarmControllerRef.value.closeSimulator();
      }
      displayNotification("Experiment completed successfully!");
    } else if (stoppedExperiment.status === "Stopped") {
      console.log("Training stopped manually.");
      displayNotification("Experiment stopped.");
    }
  } else {
    console.warn("handleTrainingStopped called with invalid experiment data");
    // Fetch the latest experiment data
    await fetchExperimentsList();
  }
};

const getConnectedRobots = () => {
  return robotSwarmControllerRef.value ? robotSwarmControllerRef.value.robots : [];
};

const handleExperimentFormOpened = () => {
  console.log("HomePage: Experiment form opened");
  if (robotSwarmControllerRef.value) {
    robotSwarmControllerRef.value.handleExperimentFormOpened();
  }
};

const handleExperimentFormClosed = () => {
  console.log("HomePage: Experiment form closed");
  if (robotSwarmControllerRef.value) {
    robotSwarmControllerRef.value.handleExperimentFormClosed();
  }
};

const setCurrentUser = async () => {
  const user = await AuthService.getCurrentUser();
  if (user && user._id) {
    currentUser.value = user;
    console.log("Current user set:", currentUser.value);
    console.log("User ID:", currentUser.value._id);
  } else {
    console.log("No user logged in or user ID is missing");
    currentUser.value = null;
    //await router.push('/login');
  }

  return currentUser.value;
};

// Watch for changes in the authentication state
watch(
  async () => await AuthService.getCurrentUser(),
  async (user) => {
    console.log("Auth state changed. Current user:", user);
    if (user) {
      await setCurrentUser();
      await fetchExperimentsList();
    } else {
      currentUser.value = null;
      experiments.value = [];
    }
  }
);

const handleUserLogout = async () => {
  console.log("User logout event received, stopping all processes...");
  if (robotSwarmControllerRef.value) {
    await robotSwarmControllerRef.value.stopAllProcesses();
  }

  currentUser.value = null;
  experiments.value = [];
  // Remove the router.push here, as it's now handled in AuthService
};

const handleRouteChange = async (to, from, next) => {
  if (robotSwarmControllerRef.value) {
    await robotSwarmControllerRef.value.stopAllProcesses();
  }
  next();
};

onMounted(async () => {
  console.log("HomePage mounted");
  try {
    const user = await setCurrentUser();
    console.log("Current user after setCurrentUser:", user);
    if (user) {
      console.log("User is authenticated, fetching experiments");
      await fetchExperimentsList();
    } else {
      console.log("User is not authenticated");
      await AuthService.logout(true);
    }

    if (robotSwarmControllerRef.value && robotSwarmControllerRef.value.openSimulator) {
      console.log("openSimulator function is available");
    } else {
      console.log("openSimulator function is not available");
    }

    window.addEventListener('message', handleSimulatorMessage);

    // Listen for login and logout events
    window.addEventListener("user-login", async () => {
      await setCurrentUser();
      await fetchExperimentsList();
    });
    window.addEventListener("user-logout", handleUserLogout);
    router.beforeEach(handleRouteChange);

    // Initialize logs from RobotSwarmController
    if (robotSwarmControllerRef.value && robotSwarmControllerRef.value.getAllLogs) {
      logs.value = robotSwarmControllerRef.value.getAllLogs();
    } else {
      console.warn("getAllLogs method is not available on robotSwarmControllerRef");
    }

    if (logContainer.value) {
      logContainer.value.addEventListener("scroll", handleScroll);
    }

    // Add global error event listener
    window.addEventListener("error", async (event) => {
      await handleGlobalError(event.error);
    });
  } catch (error) {
    console.error("Error during HomePage mount:", error);
    await handleGlobalError(error);
  }
});

// Clean up event listeners
onUnmounted(() => {
  window.removeEventListener('message', handleSimulatorMessage);
  window.removeEventListener("user-login", setCurrentUser);
  window.removeEventListener("user-logout", handleUserLogout);
  router.beforeEach(handleRouteChange);

  if (logContainer.value) {
    logContainer.value.removeEventListener("scroll", handleScroll);
  }
});

// Add this function to handle log updates
const handleLogUpdated = (updatedLogs) => {
  logs.value = updatedLogs;
  nextTick(() => {
    if (isScrolledToBottom.value && !userScrolled.value) {
      scrollToBottom();
    }
  });
};

const scrollToBottom = () => {
  if (logContainer.value) {
    logContainer.value.scrollTop = logContainer.value.scrollHeight;
  }
};

const handleScroll = () => {
  if (logContainer.value) {
    const { scrollTop, scrollHeight, clientHeight } = logContainer.value;
    isScrolledToBottom.value = scrollTop + clientHeight >= scrollHeight - 10;
    userScrolled.value = !isScrolledToBottom.value;
  }
};

// Add this function to get the icon for each log type
const getIcon = (type) => {
  switch (type) {
    case "error":
      return "fa-exclamation-circle";
    case "notice":
      return "fa-info-circle";
    case "send":
      return "fa-paper-plane";
    case "receive":
      return "fa-envelope";
    case "subscribe":
      return "fa-rss";
    case "unsubscribe":
      return "fa-times";
    default:
      return "fa-circle";
  }
};

const handleStartExperimentTraining = (experiment, isRetrain = false) => {
  console.log("HomePage: Handling start-experiment-training event", experiment, isRetrain);
  if (robotSwarmControllerRef.value && robotSwarmControllerRef.value.startExperimentTraining) {
    robotSwarmControllerRef.value.startExperimentTraining(experiment, isRetrain);
  } else {
    console.error('RobotSwarmController component or startExperimentTraining method is not available');
  }
};

defineEmits(["experiment-form-opened", "experiment-form-closed"]);
</script>

<style scoped>
/* Add these styles for the logs */
.logs {
  position: relative;
  background-color: #222;
  height: 20rem;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.log-entry {
  padding: 2px 5px;
}

.log-entry i.fas {
  margin-right: 5px;
}

.log-entry pre,
.log-entry div {
  padding: 3px;
  font-family: "Courier New", monospace;
  overflow-x: auto;
  color: #ddd;
}

.log-entry .message {
  margin-left: 10px;
}

.log-error {
  color: #ff5555;
}
.log-notice {
  color: #ffcc55;
}
.log-send {
  color: #55ff55;
}
.log-receive {
  color: #55ddff;
}
.log-subscribe {
  color: #9575cd;
}
.log-unsubscribe {
  color: #ff7f50;
}

.timestamp {
  color: #888;
}
.topic {
  color: #ffcc55;
  font-weight: bold;
}
.payload {
  color: #9cdf55;
}

.fixed {
  @apply bg-gray-100 text-gray-800;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}
</style>