<template>
  <div class="space-y-4">
    <h1 class="text-3xl font-bold">Experiments</h1>
    <p class="text-gray-600">
      View the status and metrics of your reinforcement training experiments.
    </p>

    <!-- New Experiment Button -->
    <div class="mb-4">
      <button
        @click="toggleExperimentForm"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {{ showExperimentForm ? "Cancel" : "New Experiment" }}
      </button>
    </div>

    <!-- Experiment Form -->
    <div v-if="showExperimentForm" class="mb-4">
      <ExperimentForm
        @experiment-added="handleExperimentAdded"
        :robotSwarmController="robotSwarmControllerData"
      />
    </div>

    <!-- Loading indicator -->
    <div v-if="isLoading" class="flex justify-center items-center py-8">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
    </div>

    <div v-else class="overflow-x-auto relative">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
            >
              Reward
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
            >
              Accuracy
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
            >
              Progress
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
            >
              Training Aim
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="experiment in props.experiments" :key="experiment._id">
            <td class="px-6 py-4 whitespace-nowrap">
              <div
                @click="handleViewExperimentDetails(experiment)"
                class="cursor-pointer hover:text-blue-600"
              >
                {{ experiment.name }}
                <div class="text-xs text-gray-500">
                  Robots: {{ experiment.numRobots }}
                  <template v-if="experiment.isSimulation">
                    , Obstacles: {{ experiment.numObstacles }}, Objects:
                    {{ experiment.numObjects }}
                  </template>
                </div>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <span :class="getStatusClass(experiment.status)">
                {{ experiment.status }}
              </span>
            </td>
            <td class="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
              {{ experiment.reward != null ? experiment.reward.toFixed(1) : "N/A" }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap hidden md:table-cell">
              {{
                experiment.accuracy != null
                  ? (experiment.accuracy).toFixed(2)
                  : "N/A"
              }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap hidden lg:table-cell">
              {{ experiment.currentEpisode }} / {{ experiment.numEpisodes }} episodes
            </td>
            <td class="px-6 py-4 whitespace-nowrap hidden md:table-cell">
              {{ experiment.trainingAim }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="relative">
                <button
                  @click="toggleActionList(experiment._id, $event)"
                  class="text-gray-400 hover:text-gray-600"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <div
                  v-if="activeActionList === experiment._id"
                  class="action-menu origin-bottom-right absolute right-0 bottom-full mb-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div class="py-1" role="none">
                    <button
                      @click="handleViewExperimentDetails(experiment)"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      View
                    </button>

                    <button
                      v-if="experiment.status === 'Pending'"
                      @click="handleTrainExperiment(experiment)"
                      :disabled="
                        isAnyExperimentRunning && experiment.status !== 'Running'
                      "
                      :class="[
                        'block w-full text-left px-4 py-2 text-sm',
                        isAnyExperimentRunning && experiment.status !== 'Running'
                          ? 'text-gray-400 cursor-not-allowed'
                          : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                      ]"
                      role="menuitem"
                    >
                      Train
                    </button>

                    <button
                      v-if="experiment.status === 'Running'"
                      @click="handleStopExperiment(experiment)"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Stop
                    </button>

                    <button
                      v-if="
                        experiment.status === 'Stopped' ||
                        experiment.status === 'Completed'
                      "
                      @click="handleRetrainExperiment(experiment)"
                      :disabled="
                        isAnyExperimentRunning && experiment.status !== 'Running'
                      "
                      :class="[
                        'block w-full text-left px-4 py-2 text-sm',
                        isAnyExperimentRunning && experiment.status !== 'Running'
                          ? 'text-gray-400 cursor-not-allowed'
                          : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                      ]"
                      role="menuitem"
                    >
                      {{ experiment.currentEpisode === 1 ? "Train" : "Retrain" }}
                    </button>

                    <button
                      v-if="experiment.isSimulation && experiment.status !== 'Running'"
                      :disabled="
                        isAnyExperimentRunning && experiment.status !== 'Running'
                      "
                      @click="handleTestExperiment(experiment)"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Test
                    </button>

                    <button
                      @click="handleDeleteExperiment(experiment)"
                      class="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-red-100 hover:text-red-900"
                      role="menuitem"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
// Vue imports
import { ref, onMounted, onUnmounted, computed } from "vue";

// Component imports
import ExperimentForm from "./ExperimentForm.vue";

// Service imports
import {
  addExperiment,
  deleteExperiment,
  trainExperiment,
  updateExperiment,
  retrainExperiment,
} from "@/services/ExperimentService";

// Props definition
const props = defineProps({
  experiments: {
    type: Array,
    required: true,
  },
  robotSwarmControllerData: Object,
  getConnectedRobots: Function,
  openSimulator: {
    type: Function,
    required: true,
  },
});

// Reactive state
const activeActionList = ref(null);
const showExperimentForm = ref(false);
const isLoading = ref(true);
const isSubmitting = ref(false);

// Emits (if any)
const emit = defineEmits([
  "experiment-added",
  "experiment-list-updated",
  "experiment-updated",
  "train-experiment",
  "stop-experiment",
  "retrain-experiment",
  "resume-experiment",
  "delete-experiment",
  "view-experiment-details",
  "experiment-form-opened",
  "experiment-form-closed",
  "experiment-updated",
]);

const getStatusClass = (status) => {
  switch (status) {
    case "Pending":
      return "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800";
    case "Running":
      return "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800";
    case "Completed":
      return "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800";
    case "Stopped":
      return "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800";
    default:
      return "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800";
  }
};

const toggleActionList = (experimentId, event) => {
  event.stopPropagation();

  if (activeActionList.value === experimentId) {
    activeActionList.value = null;
  } else {
    activeActionList.value = experimentId;
  }
};

const hideActionList = () => {
  activeActionList.value = null;
};

const updateExperimentInList = (updatedExperiment) => {
  emit("experiment-updated", updatedExperiment);
};

const isAnyExperimentRunning = computed(() => {
  return props.experiments.some((exp) => exp.status === "Running");
});

const handleTrainExperiment = async (experiment) => {
  if (isAnyExperimentRunning.value && experiment.status !== "Running") {
    alert(
      "Another experiment is currently running. Please wait for it to finish or stop it before starting a new one."
    );
    return;
  }

  try {
    console.log("ExperimentList: handleTrainExperiment called", experiment);
    hideActionList();

    // Check if it's a real-world experiment and if the required number of robots are connected
    if (!experiment.isSimulation) {
      const connectedRobots = props.getConnectedRobots();
      if (connectedRobots.length < experiment.numRobots) {
        alert(
          `[Error] Cannot start real-world training. Expected ${experiment.numRobots} robots, but ${connectedRobots.length} are connected.`
        );
        return;
      }
    }

    // Update the experiment status locally
    const updatedExperiment = { ...experiment, status: "Running" };
    updateExperimentInList(updatedExperiment);

    console.log("ExperimentList: Emitting experiment-updated event");
    emit("experiment-updated", updatedExperiment);
    console.log(
      "ExperimentList: Emitting start-experiment-training event",
      updatedExperiment
    );
    emit("start-experiment-training", updatedExperiment);

    // Update the experiment status on the server
    await updateExperiment(experiment._id, { status: "Running" });
  } catch (error) {
    console.error("Failed to start training experiment:", error);
    alert(
      `Failed to start training experiment: ${
        error.response?.data?.message || error.message
      }`
    );
    // Revert the status if there's an error
    updateExperimentInList({ ...experiment, status: "Pending" });
  }
};

const handleRetrainExperiment = async (experiment) => {
  if (isAnyExperimentRunning.value && experiment.status !== "Running") {
    alert(
      "Another experiment is currently running. Please wait for it to finish or stop it before retraining."
    );
    return;
  }

  try {
    console.log("ExperimentList: handleRetrainExperiment called", experiment);
    hideActionList();

    // Update the experiment status locally
    const updatedExperiment = { ...experiment, status: "Running" };
    updateExperimentInList(updatedExperiment);

    if (experiment.currentEpisode === 1) {
      await trainExperiment(experiment._id);
    } else {
      await retrainExperiment(experiment._id);
    }

    console.log("ExperimentList: Emitting experiment-updated event");
    emit("experiment-updated", updatedExperiment);
    console.log(
      "ExperimentList: Emitting start-experiment-training event",
      updatedExperiment
    );
    emit("start-experiment-training", updatedExperiment, true);

    // Update the experiment status on the server
    await updateExperiment(experiment._id, { status: "Running" });
  } catch (error) {
    console.error("Failed to retrain experiment:", error);
    // Revert the status if there's an error
    updateExperimentInList({
      ...experiment,
      status: experiment.currentEpisode === 1 ? "Pending" : "Stopped",
    });
  }
};

const handleStopExperiment = async (experiment) => {
  try {
    console.log("ExperimentList: handleStopExperiment called", experiment);
    if (!experiment || !experiment._id) {
      console.error("Invalid experiment object:", experiment);
      alert("Failed to stop experiment: Invalid experiment data");
      return;
    }
    hideActionList();
    emit("stop-experiment", experiment);
  } catch (error) {
    console.error("Failed to stop experiment:", error);
    alert(`Failed to stop experiment: ${error.message}`);
  }
};

const handleDeleteExperiment = async (experiment) => {
  hideActionList();
  if (confirm("Are you sure you want to delete this experiment?")) {
    try {
      await deleteExperiment(experiment._id);
      emit("delete-experiment", experiment);
    } catch (error) {
      console.error("Failed to delete experiment:", error);
    }
  }
};

const handleViewExperimentDetails = async (experiment) => {
  try {
    hideActionList();
    console.log("Viewing experiment details:", experiment);
    emit("view-experiment-details", experiment);
  } catch (error) {
    console.error("Failed to view experiment details:", error);
  }
};

const handleClickOutside = (event) => {
  const actionMenu = document.querySelector(".action-menu");
  const actionButton = event.target.closest("button");

  if (
    activeActionList.value &&
    !actionMenu?.contains(event.target) &&
    !actionButton?.classList.contains("fa-ellipsis-v")
  ) {
    hideActionList();
  }
};

const handleExperimentAdded = async (experimentData) => {
  if (isSubmitting.value) return; // Prevent double submission
  isSubmitting.value = true;

  try {
    const newExperiment = await addExperiment(experimentData);
    emit("experiment-added", { newExperiment });
    showExperimentForm.value = false;
    emit("experiment-form-closed");
  } catch (error) {
    console.error("Failed to add experiment:", error);
  } finally {
    isSubmitting.value = false;
  }
};

const handleScroll = () => {
  hideActionList();
};

const toggleExperimentForm = () => {
  showExperimentForm.value = !showExperimentForm.value;
  if (showExperimentForm.value) {
    console.log("ExperimentList: Emitting experiment-form-opened");
    emit("experiment-form-opened");
  } else {
    console.log("ExperimentList: Emitting experiment-form-closed");
    emit("experiment-form-closed");
  }
};

const handleTestExperiment = (experiment) => {
  if (experiment.isSimulation) {
    console.log("Calling openSimulator with experiment ID:", experiment._id);
    if (props.openSimulator) {
      props.openSimulator(experiment._id);
    } else {
      console.error("openSimulator function is not available");
    }
  } else {
    console.log("Experiment is not a simulation");
  }
  hideActionList();
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
  window.addEventListener("scroll", handleScroll);
  setTimeout(() => {
    isLoading.value = false;
  }, 1000);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
  window.removeEventListener("scroll", handleScroll);
});

defineExpose({
  updateExperimentInList,
});
</script>

<style scoped>
.overflow-x-auto {
  overflow-x: visible;
}

.action-menu {
  position: absolute;
  z-index: 50;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.5rem; /* Adds a small gap between the button and the menu */
}

.relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
