const ppoConfig = {
  nSteps: 1024,
  nEpisodes: 10,
  policyLearningRate: 1e-4,
  valueLearningRate: 1e-4,
  clipRatio: 0.2,
  targetKL: 0.01,
  netArch: {
    pi: [64, 64], // Two hidden layers with 64 units each for policy network
    vf: [64, 64] // Two hidden layers with 64 units each for value function network
  },
  activation: 'tanh',
  verbose: 1,
  inputShape: 33
};

export default ppoConfig;