export default class SharedBuffer {
    constructor(maxSize) {
        this.buffer = [];
        this.maxSize = maxSize;
    }

    add(experience) {
        this.buffer.push(experience);
        if (this.buffer.length > this.maxSize) {
            this.buffer.shift();
        }
    }

    sample(batchSize) {
        const samples = [];
        for (let i = 0; i < batchSize; i++) {
            const index = Math.floor(Math.random() * this.buffer.length);
            samples.push(this.buffer[index]);
        }
        return samples;
    }

    get length() {
        return this.buffer.length;
    }
}