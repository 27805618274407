<template>
  <form @submit.prevent="submitForm" class="space-y-4">
    <div v-for="field in formFields" :key="field.key">
      <label :for="field.key" class="block text-sm font-medium text-gray-700">{{
        field.label
      }}</label>
      <input
        v-if="field.type !== 'textarea'"
        :id="field.key"
        v-model="formData[field.key]"
        :type="field.type"
        :min="field.min || 1"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        @input="updateFormFields"
      />
      <textarea
        v-else
        :id="field.key"
        v-model="formData[field.key]"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        @input="updateFormFields"
      ></textarea>
    </div>
    <div>
      <label for="trainingAim" class="block text-sm font-medium text-gray-700"
        >Training Aim</label
      >
      <select
        v-model="formData.trainingAim"
        id="trainingAim"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md"
        @change="updateFormFields"
      >
        <option value="disperse">Disperse</option>
        <option value="gather">Gather</option>
        <option value="explore">Explore</option>
      </select>
    </div>
    <div>
      <label for="isSimulation" class="block text-sm font-medium text-gray-700"
        >Simulation Mode</label
      >
      <input
        id="isSimulation"
        v-model="formData.isSimulation"
        type="checkbox"
        class="mt-1"
        @change="updateFormFields"
      />
    </div>
    <!-- New fields for simulation mode -->
    <div v-if="formData.isSimulation">
      <label for="numObjects" class="block text-sm font-medium text-gray-700"
        >Number of Objects</label
      >
      <input
        id="numObjects"
        v-model.number="formData.numObjects"
        type="number"
        min="1"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        @input="updateFormFields"
      />
    </div>
    <div v-if="formData.isSimulation">
      <label for="numObstacles" class="block text-sm font-medium text-gray-700"
        >Number of Obstacles</label
      >
      <input
        id="numObstacles"
        v-model.number="formData.numObstacles"
        type="number"
        min="1"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        @input="updateFormFields"
      />
    </div>
    <button
      type="submit"
      :disabled="isSubmitting"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      {{ isSubmitting ? "Submitting..." : "Create Experiment" }}
    </button>
  </form>
</template>

<script setup>
// Vue imports
import { reactive, watch, onMounted, ref } from "vue";

// Props definition
const props = defineProps({
  robotSwarmController: {
    type: Object,
    required: true,
  },
});

// Form configuration
const formFields = [
  { key: "name", label: "Experiment Name", type: "text" },
  { key: "description", label: "Description", type: "textarea" },
  { key: "numRobots", label: "Number of Robots", type: "number" },
  { key: "numEpisodes", label: "Number of Episodes", type: "number" },
];

// Reactive state
const formData = reactive({
  name: "",
  description: "",
  numRobots: 5,
  numEpisodes: 10,
  trainingAim: "disperse",
  isSimulation: false,
  numObjects: 1,
  numObstacles: 1,
  submissionId: "",
});

const userEdited = ref({
  name: false,
  description: false,
  numRobots: false,
  numEpisodes: false,
  numObjects: false,
  numObstacles: false,
});

const isSubmitting = ref(false);
const submitAttempted = ref(false);

// Emits definition
const emit = defineEmits(["experiment-added"]);

const updateFormData = () => {
  console.log("Updating form data with:", props.robotSwarmController);
  if (props.robotSwarmController) {
    formData.isSimulation = props.robotSwarmController.isTestMode;

    if (!userEdited.value.numRobots) formData.numRobots = 5; // Default value
    if (!userEdited.value.numEpisodes) formData.numEpisodes = 10; // Default value
    if (!userEdited.value.numObjects) formData.numObjects = 1; // Default value
    if (!userEdited.value.numObstacles) formData.numObstacles = 1; // Default value

    if (isDefaultName(formData.name) || formData.name.trim() === "") updateName();
    if (isDefaultDescription(formData.description) || formData.description.trim() === "")
      updateDescription();
    console.log(
      "Form data updated:",
      formData.name,
      formData.description,
      formData.numRobots,
      formData.numEpisodes,
      formData.numObjects,
      formData.numObstacles
    );
  } else {
    console.warn("robotSwarmController is undefined");
  }
};

const updateFormFields = () => {
  updateName();
  updateDescription();
};

const updateName = () => {
  formData.name = `Experiment_${formData.isSimulation ? 'Sim' : 'Real'}_${formData.numRobots}R_${formData.numEpisodes}E`;
  if (formData.isSimulation) {
    formData.name += `_${formData.numObjects}O_${formData.numObstacles}Obs`;
  }
};

const updateDescription = () => {
  formData.description = `${formData.isSimulation ? 'Simulation' : 'Real-world'} experiment with ${formData.numRobots} robots and ${formData.numEpisodes} episodes.`;
  if (formData.isSimulation) {
    formData.description += ` ${formData.numObjects} objects and ${formData.numObstacles} obstacles.`;
  }
};

const isDefaultName = (name) => {
  const regex = /^Experiment_(Sim|Real)_\d+R_\d+E$/;
  return regex.test(name);
};

const isDefaultDescription = (description) => {
  const regex = /^(Simulation|Real-world) experiment with \d+ robots and \d+ episodes\.$/;
  return regex.test(description);
};

onMounted(() => {
  console.log("ExperimentForm mounted, props:", props);
  updateFormData();
});


watch(
  () => props.robotSwarmController,
  (newVal) => {
    console.log("robotSwarmController changed:", newVal);
    updateFormData();
  },
  { deep: true }
);

// Watch for changes in all form fields
Object.keys(formData).forEach((field) => {
  watch(
    () => formData[field],
    () => {
      userEdited.value[field] = true;
      updateFormFields();
    }
  );
});

const submitForm = async () => {
  if (isSubmitting.value || submitAttempted.value) return;

  submitAttempted.value = true;
  isSubmitting.value = true;

  try {
    // Reset userEdited flags before submitting
    Object.keys(userEdited.value).forEach((key) => {
      userEdited.value[key] = false;
    });

    emit("experiment-added", {...formData});
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    isSubmitting.value = false;
    // We don't reset submitAttempted here to prevent multiple submissions
  }
};

// Reset submitAttempted when the form is closed
watch(
  () => props.showForm,
  (newValue) => {
    if (!newValue) {
      submitAttempted.value = false;
    }
  }
);

watch(
  () => props.experiments,
  (newExperiments) => {
    if (isSubmitting.value && newExperiments.length > 0) {
      isSubmitting.value = false;
    }
  },
  { deep: true }
);
</script>
